// Cards
@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Customize colors
@import "colors";

// Custom Mixins
@import "mixins";

//Bootstrap Custom
@import "BootstrapCustom/card";

@include media-breakpoint-up(lg) {
    .card-body {
        padding: $card-spacer-x-lg;
    }
}

//
// Card Group Element
//
.card-group-element {
    margin: -$card-deck-margin;
    margin-bottom: -$card-deck-margin !important;
    display: flex;
    flex-wrap: wrap;
}

.card-group-element-item {
    width: 100%;
    padding: $card-deck-margin;

    .card {
        height: 100%;
    }

    .card-title {
        margin-bottom: $card-spacer-y;
    }

    .card-subtitle {
        margin-bottom: $card-spacer-y;
    }

    .card-body {
        background: $white;
    }

    .card-title + .card-subtitle {
        margin-top: -$card-spacer-y;
    }

    .card-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        border-top: 0;
        padding: $card-spacer-x;
        background-color: transparent;
        flex-wrap: wrap;

        .btn-link {
            white-space: nowrap;
        }
    }

    @include media-breakpoint-up(lg) {
        .card-body {
            padding: $card-spacer-x-lg $card-spacer-x-lg $card-spacer-x-lg / 4;
        }

        .card-footer {
            padding: $card-spacer-x-lg / 2 $card-spacer-x-lg;
        }
    }
}

.card-group-element-columns-2 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: calc(100% / 2);
        }
    }
}

.card-group-element-columns-3 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: calc(100% / 2);
        }

        @include media-breakpoint-up('lg') {
            width: calc(100% / 3);
        }
    }
}

.card-group-element-columns-4 {
    .card-group-element-item {
        @include media-breakpoint-up('sm') {
            width: calc(100% / 2);
        }
        @include media-breakpoint-up('xl') {
            width: calc(100% / 4);
        }
    }
}