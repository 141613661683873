@import "card";
.top-offers-content {
  .card-group-element-item {
    height: 100%;
    padding: 0;
  }

  .readmore-wrapper {
    margin-top: 1.125rem;

    .btn:not(:last-of-type) {
      @media (max-width: 440px) {
        margin-left: 1.9rem;
      }
    }

    @include media-breakpoint-up(lg) {
      margin-top: 2.375rem;
    }
  }

  .card-img-top {
    position: relative;

    img {
      width: 100%;
    }

    .ribbon {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 250px;
      display: block;
      width: 100%;
      text-align: center;
      color: white;
      padding: 10px 0;

      span {
        font-weight: 400;
        font-size: 1.125rem;
      }
    }
  }
}
