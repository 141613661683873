@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Customize colors
@import "colors";

// Custom Mixins
@import "mixins";


//https://css-tricks.com/snippets/css/fluid-typography/
@mixin fluid-type($min-value, $max-value, $breakpoint-min:xxl, $breakpoint-max:xxxl) {
	& {
		font-size : $min-value;

		@include media-breakpoint-up($breakpoint-min) {
			font-size : calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * ((100vw - #{breakpoint-rem($breakpoint-min)}rem) / #{strip-unit(breakpoint-rem($breakpoint-max) - breakpoint-rem($breakpoint-min))}));
		}

		@include media-breakpoint-up($breakpoint-max) {
			font-size : $max-value;
		}
	}
}

@function breakpoint-rem($breakpoint) {
	@return strip-unit(breakpoint-min($breakpoint) / 16);
}

@function strip-unit($value) {
	@return $value / ($value * 0 + 1);
}


//
// Saunaaufgussplan
//

.tx-wvier-saunaaufgussplan {
	&.container-fluid {
		padding : 0;
	}

	.table {
		margin-bottom : 14px;

		thead {
			tr {
				td {
					padding-top    : 0.25rem;
					padding-bottom : 0.25rem;
				}
			}
		}

		tbody {
			tr {
				td {
					padding-top    : 0.1875rem;
					padding-bottom : 0.1875rem;

					p {
						margin-bottom : 0;
						text-align    : left;
					}

					&:first-child {
						width      : 100px;
						text-align : center;
					}
				}

				&.fallbacktext {
					td {
						&:first-child {
							text-align    : left;
							border-bottom : 0;
						}
					}
				}
			}
		}

		&.other-sauna {
			tbody {
				td {
					border-bottom : 0;
				}
			}
		}
	}
}

main:not(.infusionplan) {
	.tx-wvier-saunaaufgussplan {
		.table {
			margin-bottom : 40px;

			@include media-breakpoint-up(lg) {
				margin-bottom : 50px;
			}

			thead {
				td {
					padding-top    : 0.5rem;
					padding-bottom : 0.5rem;
				}

				strong {
					font-size     : $h4-font-size;
					margin-bottom : $headings-margin-bottom * 0.625;
					line-height   : 1.1;

					@include media-breakpoint-up(lg) {
						font-size : $h4-font-size-lg;
					}

					.smallsubtitle {
						font-size : 1rem;
					}
				}
			}

			tbody {
				td {
					font-size      : $font-size-base;
					padding-top    : 0.4rem;
					padding-bottom : 0.4rem;
				}
			}
		}

		@include media-breakpoint-up(lg) {
			.other-saunas {
				flex-wrap : wrap;

				.table-responsive {
					padding-left  : 15px;
					padding-right : 15px;
					width         : 25%;

					&:first-child {
						padding-right : 15px;
						padding-left  : 0;
					}

					&:last-child {
						padding-left  : 15px;
						padding-right : 0;
					}

					p {
						font-size : $font-size-base;
					}
				}
			}
		}
	}
}

.infusionplan {
	.container {
		margin-left : 0;
	}

	.frame {
		margin : 15px;

		h2 {
			@include fluid-type(2rem, 6rem);
			margin-bottom : 0.5rem;
		}

		.col-lg-4,
		.col-lg-8 {
			&:first-child {
				padding-left : 0;
			}

			&:last-child {
				padding-right : 0;
			}
		}
	}
}

.tx-wvier-saunaaufgussplan {
	&.container-fluid {
		padding : 0;
	}

	.row {
		margin : 0;
	}

	.aufgussplan-splitscreen {
		.table {
			margin-bottom : 14px;

			thead {
				tr {
					td {
						@include fluid-type(1.375rem, 3.7rem);
						padding-top    : 0.25rem;
						padding-bottom : 0.25rem;

						.smallsubtitle {
							@include fluid-type(0.6875rem, 2rem);
						}
					}
				}
			}

			tbody {
				tr {
					td {
						@include fluid-type(0.9rem, 2.67rem);
						line-height    : 1;
						padding-top    : 0.875rem;
						padding-bottom : 0.875rem;

						p {
							@include fluid-type(1rem, 2.67rem);
							line-height   : 1.2;
							margin-bottom : 0;
							text-align    : left;
						}

						&:first-child {
							width      : 100px;
							text-align : left;
						}
					}

					&.fallbacktext {
						td {
							&:first-child {
								text-align    : left;
								border-bottom : 0;
							}
						}
					}
				}
			}

			&.other-sauna {
				margin-bottom : 0;

				tbody {
					td {
						border-bottom : 0;
					}
				}

			}
		}

		.bergkristall-2col {

			tbody {
				columns : 2;
				display : block;

				&.fallbacktextbody {
					columns : 1 !important;
				}

				tr {
					width   : 100%;
					display : flex;

					td:first-child {
						display     : block;
						flex-shrink : 0;
					}

					td:last-child {
						width : 100%;
					}
				}
			}
		}

		.andere_saunas_indoor {
			display     : flex;
			align-items : flex-start;

			.table-responsive {
				&:first-child {
					margin-right : 30px;
				}

				&:last-child {
					margin-left : 30px;
				}
			}
		}
	}

	.table {
		margin-bottom : 14px;

		thead {
			tr {
				td {
					@include fluid-type(1.375rem, 3rem);
					padding-top    : 0.25rem;
					padding-bottom : 0.25rem;

					.smallsubtitle {
						@include fluid-type(0.6875rem, 1.375rem);
					}
				}
			}
		}

		tbody {
			tr {
				td {
					@include fluid-type(0.9rem, 2rem);
					padding-top    : 0.1875rem;
					padding-bottom : 0.1875rem;

					p {
						@include fluid-type(1rem, 2rem);
						margin-bottom : 0;
						text-align    : left;
					}

					&:first-child {
						width      : 100px;
						text-align : center;
					}
				}

				&.fallbacktext {
					td {
						&:first-child {
							text-align    : left;
							border-bottom : 0;
						}
					}
				}
			}
		}

		&.other-sauna {
			margin-bottom : 0;

			tbody {
				td {
					border-bottom : 0;
				}
			}

		}
	}
}

@include media-breakpoint-up(xxl) {
	.infusionplan {
		max-width : 100%;

		.frame {
			margin : 30px;

			h2 {
				margin-bottom : 1.1rem;
			}

			.aufgussplan-splitscreen {

				&.aufgussplan-outdoor {
					margin-top : 100px;
				}

				.col-lg-4,
				.col-lg-8 {
					padding-left  : 30px;
					padding-right : 30px;

					&:first-child {
						padding-left : 0;
					}

					&:last-child {
						padding-right : 0;
					}

					.table {
						margin-bottom : 50px;

						thead {
							tr {
								td {
									padding : 1rem;
								}
							}
						}

						tbody {
							tr {
								td {
									padding : 1rem;
								}
							}
						}
					}
				}

				.bergkristall-2col {
					tbody {
						columns : 2;
						display : block;

						tr {
							width   : 100%;
							display : flex;

							td:first-child {
								width       : 180px;
								display     : block;
								flex-shrink : 0;
							}

							td:last-child {
								width : 100%;
							}
						}
					}
				}

				.andere_saunas_indoor {
					display     : flex;
					align-items : flex-start;

					.table-responsive {
						&:first-child {
							margin-right : 30px;
						}

						&:last-child {
							margin-left : 30px;
						}
					}
				}
			}

			.col-lg-4,
			.col-lg-8 {
				padding-left  : 30px;
				padding-right : 30px;

				&:first-child {
					padding-left : 0;
				}

				&:last-child {
					padding-right : 0;
				}

				.table {
					margin-bottom : 25px;

					thead {
						tr {
							td {
								padding-top    : 0;
								padding-bottom : 0.5rem;
							}
						}
					}

					tbody {
						tr {
							td {
								padding-bottom : 0.375rem;
								padding-top    : 0.375rem;

								&:first-child {
									width : 150px;
								}
							}
						}
					}
				}
			}

			.fallback {
				.sauna-tables {
					.table {
						margin-bottom : 40px;

						.fallbacktext {
							td {
								padding-top    : 20px;
								padding-bottom : 20px;
								border-bottom  : 0;
							}

						}
					}
				}
			}
		}
	}
}

@include media-breakpoint-up(xxxl) {
	.infusionplan {
		max-height : 100vh;
		overflow   : hidden;

		.frame {
			margin : 60px;

			h2 {
				margin-bottom : 3rem;
			}

			.aufgussplan-splitscreen {

				&.aufgussplan-outdoor {
					margin-top : 100px;
				}

				.col-lg-4,
				.col-lg-8 {
					padding-left  : 30px;
					padding-right : 30px;

					&:first-child {
						padding-left : 0;
					}

					&:last-child {
						padding-right : 0;
					}

					.table {
						margin-bottom : 50px;

						thead {
							tr {
								td {
									padding : 1.6rem;
								}
							}
						}

						tbody {
							tr {
								td {
									padding : 1.6rem;
								}
							}
						}
					}
				}

				.bergkristall-2col {
					tbody {
						columns : 2;
						display : block;

						tr {
							width   : 100%;
							display : flex;

							td:first-child {
								width       : 180px;
								display     : block;
								flex-shrink : 0;
							}

							td:last-child {
								width : 100%;
							}
						}
					}
				}

				.andere_saunas_indoor {
					display     : flex;
					align-items : flex-start;

					.table-responsive {
						&:first-child {
							margin-right : 30px;
						}

						&:last-child {
							margin-left : 30px;
						}
					}
				}
			}

			.col-lg-4,
			.col-lg-8 {
				padding-left  : 30px;
				padding-right : 30px;

				&:first-child {
					padding-left : 0;
				}

				&:last-child {
					padding-right : 0;
				}

				.table {
					margin-bottom : 50px;

					thead {
						tr {
							td {
								padding-top    : 0.5rem;
								padding-bottom : 0.5rem;
							}
						}
					}

					tbody {
						tr {
							td {
								padding-bottom : 0.375rem;
								padding-top    : 0.375rem;
							}
						}
					}
				}
			}

			.fallback {
				.sauna-tables {
					.table {
						margin-bottom : 100px;

						.fallbacktext {
							td {
								padding-top    : 30px;
								padding-bottom : 30px;
								border-bottom  : 0;
							}

						}
					}
				}
			}
		}
	}
}


