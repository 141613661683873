.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #f6f6f6;
  border: 1px solid #b8b8b8;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #6c757d;
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.collapsing.width {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing.width {
    transition: none;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Rebond Grotesque", Arial, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.33333;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: 1rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 0.9;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #b8b8b8;
}

.table thead th {
  font-weight: 300;
  vertical-align: bottom;
  border-bottom: 2px solid #b8b8b8;
}

.table tbody + tbody {
  border-top: 2px solid #b8b8b8;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #b8b8b8;
}

.table-bordered th:first-child,
.table-bordered td:first-child {
  border-left-width: 0;
}

.table-bordered th:last-child,
.table-bordered td:last-child {
  border-right-width: 0;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
  border-top-width: 0;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1373.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1919.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxl > .table-bordered {
    border: 0;
  }
}

@media (max-width: 2523.98px) {
  .table-responsive-xxxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xxxl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.gallery-row {
  display: flex;
  flex-wrap: wrap;
  margin-top: -6px;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: -6px;
}

@media (min-width: 992px) {
  .gallery-row {
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
    margin-bottom: -15px;
  }
}

.gallery-item {
  padding-top: 6px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 6px;
}

@media (min-width: 992px) {
  .gallery-item {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }
}

figure.image {
  margin: 0;
}

figure.image img,
figure.image picture {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  height: auto;
}

.gallery-item-size-1 {
  width: 100%;
}

.gallery-item-size-2 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-2 {
    width: calc(100% / 2);
  }
}

.gallery-item-size-3 {
  width: 100%;
}

@media (min-width: 576px) {
  .gallery-item-size-3 {
    width: calc(100% / 2);
  }
}

@media (min-width: 768px) {
  .gallery-item-size-3 {
    width: calc(100% / 3);
  }
}

.gallery-item-size-4 {
  width: calc(100% / 2);
}

@media (min-width: 576px) {
  .gallery-item-size-4 {
    width: calc(100% / 4);
  }
}

.teaser a {
  text-decoration: none;
}

.teaser-row .teaser {
  margin-bottom: 12px;
}

@media (max-width: 767.98px) {
  .teaser-row .teaser {
    max-width: 336px;
    margin-left: auto;
    margin-right: auto;
  }
}

@media (max-width: 575.98px) {
  .teaser-row .teaser {
    max-width: 294px;
  }
}

@media (min-width: 992px) {
  .teaser-row .teaser {
    margin-bottom: 30px;
  }
}

.teaser-top {
  position: relative;
  height: 0;
  overflow: hidden;
  color: #fff;
  padding-bottom: 150%;
}

.teaser-top:hover picture img {
  filter: brightness(1);
}

.teaser-top img {
  filter: brightness(0.8);
}

.teaser-top picture::after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.55) 0, rgba(0, 0, 0, 0) 40%);
}

.teaser-nav .teaser-top {
  padding-bottom: 75%;
}

.teaser-nav .teaser-top .teaser-title {
  font-size: 1.375rem;
}

.teaser-nav .teaser-top .teaser-title .teaser-arrow {
  top: -3px;
}

.teaser-top .img-fluid {
  min-width: 100%;
}

.teaser-top .teaser-title {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1.1;
  padding: 1.75rem 1rem;
}

@media (min-width: 1374px) {
  .teaser-top .teaser-title {
    font-size: 2.125rem;
    padding: 1.75rem 1.5rem;
  }
}

.teaser-top .teaser-title .teaser-arrow {
  position: relative;
  white-space: nowrap;
  top: -7px;
  font-size: 1.25rem;
}

.teaser-bottom {
  margin-top: 0.875rem;
  padding: 0 1rem;
  color: #000;
  margin-bottom: 20px;
}

@media (min-width: 1374px) {
  .teaser-bottom {
    padding: 0 1.5rem;
  }
}

.teaser-nav .teaser-bottom {
  color: #fff;
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .teaser-nav .teaser-title {
    font-size: 1.125rem;
  }
  .teaser-nav .teaser-title .teaser-arrow {
    font-size: 0.9375rem;
  }
  .teaser-nav .teaser-subtitle {
    font-size: 1rem;
  }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}

.mfp-preloader a {
  color: #CCC;
}

.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close,
button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}

.mfp-close:hover,
.mfp-close:focus {
  opacity: 1;
}

.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent;
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}

.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}

.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}

.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}

.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}

.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}

.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}

.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}

.mfp-figure:after {
  content: '';
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}

.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}

.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.news .row > div {
  margin-bottom: 5rem;
}

@media (min-width: 992px) {
  .news .row > div {
    margin-bottom: 6.25rem;
  }
}

.news .list-view-item {
  background: white;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.news .list-view-item .list-view-item-text-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 12px 15px;
}

@media (min-width: 768px) {
  .news .list-view-item .list-view-item-text-wrapper {
    padding: 22px 25px;
  }
}

.news .list-view-item .list-view-item-text-wrapper .list-view-item-body {
  flex: 1;
}

.news .list-view-item .btn-read-more-teaser .close-text {
  display: none;
}

.news .list-view-item .btn-read-more-teaser .read-more-text {
  display: flex;
}

.news .list-view-item .btn-read-more-teaser:not(.collapsed) .close-text {
  display: inline;
}

.news .list-view-item .btn-read-more-teaser:not(.collapsed) .read-more-text {
  display: none;
}

.news .page-navigation .f3-widget-paginator {
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
}

.news .page-navigation .f3-widget-paginator li {
  margin-top: 0 !important;
  margin-right: 25px;
}

.news .page-navigation .f3-widget-paginator li.current {
  font-weight: 500;
  color: #000;
}

.news .page-navigation .f3-widget-paginator li:last-child {
  margin-right: 0;
}

.news .page-navigation .f3-widget-paginator li a {
  font-weight: 500;
  text-decoration: none;
}

.news .page-navigation .f3-widget-paginator li a:hover {
  color: #000;
}

.news .news-slider-view ul li:focus {
  outline: none;
}

.news .news-slider-view ul li .list-view-item {
  margin-bottom: 0.625rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  /*@include media-breakpoint-up(lg) {
                        margin-bottom: 1.875rem;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 3.75rem;
                    }*/
}

.news .news-slider-view ul li .list-view-item .list-view-item-text-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.news .news-slider-view ul li .list-view-item .list-view-item-text-wrapper .list-view-item-body {
  flex: 1;
}

.news .news-slider-view .page-navigation {
  display: none;
}

.news .news-slider-view .readmore-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  /* @include media-breakpoint-up(lg) {
                display: block;
                margin-top: 0;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }*/
}

.news .news-slider-view .readmore-wrapper .btn {
  z-index: 102;
}

.news:not(.text-teaser) .news-slider-view .splide__arrows {
  margin: 0 !important;
  margin-top: 40px !important;
}

@media (min-width: 992px) {
  .news:not(.text-teaser) .news-slider-view .splide__arrows {
    transform: translateY(70px);
  }
}

.news.news-single .news-img-wrap .mediaelement-image {
  display: block;
}

.news.news-single .news-img-wrap .mediaelement-image img {
  width: 100%;
  margin: 0;
}

.news.news-single .news-maincontent-wrapper {
  margin-top: 5rem;
}

@media (min-width: 992px) {
  .news.news-single .news-maincontent-wrapper {
    margin-top: 6.25rem;
  }
}

.news.news-single .news-related-wrap ul {
  list-style-type: none;
  padding-left: 0;
}

.news.news-single .news-related-wrap ul li {
  display: flex;
}

.news.news-single .news-related-wrap ul li a {
  text-decoration: none;
}

.news.news-single .news-related-wrap ul li .news-related-files-size {
  font-weight: 300;
  margin-left: 10px;
  color: #000;
  flex-shrink: 0;
}

/*!
 * Datepicker for Bootstrap v1.9.0 (https://github.com/uxsolutions/bootstrap-datepicker)
 *
 * Licensed under the Apache License v2.0 (http://www.apache.org/licenses/LICENSE-2.0)
 */
.datepicker {
  padding: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  direction: ltr;
}

.datepicker-inline {
  width: 220px;
}

.datepicker-rtl {
  direction: rtl;
}

.datepicker-rtl.dropdown-menu {
  left: auto;
}

.datepicker-rtl table tr td span {
  float: right;
}

.datepicker-dropdown {
  top: 0;
  left: 0;
}

.datepicker-dropdown:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #999;
  border-top: 0;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.datepicker-dropdown:after {
  content: '';
  display: inline-block;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-top: 0;
  position: absolute;
}

.datepicker-dropdown.datepicker-orient-left:before {
  left: 6px;
}

.datepicker-dropdown.datepicker-orient-left:after {
  left: 7px;
}

.datepicker-dropdown.datepicker-orient-right:before {
  right: 6px;
}

.datepicker-dropdown.datepicker-orient-right:after {
  right: 7px;
}

.datepicker-dropdown.datepicker-orient-bottom:before {
  top: -7px;
}

.datepicker-dropdown.datepicker-orient-bottom:after {
  top: -6px;
}

.datepicker-dropdown.datepicker-orient-top:before {
  bottom: -7px;
  border-bottom: 0;
  border-top: 7px solid #999;
}

.datepicker-dropdown.datepicker-orient-top:after {
  bottom: -6px;
  border-bottom: 0;
  border-top: 6px solid #fff;
}

.datepicker table {
  margin: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.datepicker td,
.datepicker th {
  text-align: center;
  width: 20px;
  height: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  border: none;
}

.table-striped .datepicker table tr td,
.table-striped .datepicker table tr th {
  background-color: transparent;
}

.datepicker table tr td.day:hover,
.datepicker table tr td.day.focused {
  background: #eee;
  cursor: pointer;
}

.datepicker table tr td.old,
.datepicker table tr td.new {
  color: #999;
}

.datepicker table tr td.disabled,
.datepicker table tr td.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td.highlighted {
  background: #d9edf7;
  border-radius: 0;
}

.datepicker table tr td.today,
.datepicker table tr td.today:hover,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today.disabled:hover {
  background-color: #fde19a;
  background-image: -moz-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -ms-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#fdd49a), to(#fdf59a));
  background-image: -webkit-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: -o-linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-image: linear-gradient(to bottom, #fdd49a, #fdf59a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fdd49a', endColorstr='#fdf59a', GradientType=0);
  border-color: #fdf59a #fdf59a #fbed50;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #000;
}

.datepicker table tr td.today:hover,
.datepicker table tr td.today:hover:hover,
.datepicker table tr td.today.disabled:hover,
.datepicker table tr td.today.disabled:hover:hover,
.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active,
.datepicker table tr td.today.disabled,
.datepicker table tr td.today:hover.disabled,
.datepicker table tr td.today.disabled.disabled,
.datepicker table tr td.today.disabled:hover.disabled,
.datepicker table tr td.today[disabled],
.datepicker table tr td.today:hover[disabled],
.datepicker table tr td.today.disabled[disabled],
.datepicker table tr td.today.disabled:hover[disabled] {
  background-color: #fdf59a;
}

.datepicker table tr td.today:active,
.datepicker table tr td.today:hover:active,
.datepicker table tr td.today.disabled:active,
.datepicker table tr td.today.disabled:hover:active,
.datepicker table tr td.today.active,
.datepicker table tr td.today:hover.active,
.datepicker table tr td.today.disabled.active,
.datepicker table tr td.today.disabled:hover.active {
  background-color: #fbf069 \9;
}

.datepicker table tr td.today:hover:hover {
  color: #000;
}

.datepicker table tr td.today.active:hover {
  color: #fff;
}

.datepicker table tr td.range,
.datepicker table tr td.range:hover,
.datepicker table tr td.range.disabled,
.datepicker table tr td.range.disabled:hover {
  background: #eee;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today,
.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today.disabled:hover {
  background-color: #f3d17a;
  background-image: -moz-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -ms-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#f3c17a), to(#f3e97a));
  background-image: -webkit-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: -o-linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-image: linear-gradient(to bottom, #f3c17a, #f3e97a);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3c17a', endColorstr='#f3e97a', GradientType=0);
  border-color: #f3e97a #f3e97a #edde34;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.datepicker table tr td.range.today:hover,
.datepicker table tr td.range.today:hover:hover,
.datepicker table tr td.range.today.disabled:hover,
.datepicker table tr td.range.today.disabled:hover:hover,
.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active,
.datepicker table tr td.range.today.disabled,
.datepicker table tr td.range.today:hover.disabled,
.datepicker table tr td.range.today.disabled.disabled,
.datepicker table tr td.range.today.disabled:hover.disabled,
.datepicker table tr td.range.today[disabled],
.datepicker table tr td.range.today:hover[disabled],
.datepicker table tr td.range.today.disabled[disabled],
.datepicker table tr td.range.today.disabled:hover[disabled] {
  background-color: #f3e97a;
}

.datepicker table tr td.range.today:active,
.datepicker table tr td.range.today:hover:active,
.datepicker table tr td.range.today.disabled:active,
.datepicker table tr td.range.today.disabled:hover:active,
.datepicker table tr td.range.today.active,
.datepicker table tr td.range.today:hover.active,
.datepicker table tr td.range.today.disabled.active,
.datepicker table tr td.range.today.disabled:hover.active {
  background-color: #efe24b \9;
}

.datepicker table tr td.selected,
.datepicker table tr td.selected:hover,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected.disabled:hover {
  background-color: #9e9e9e;
  background-image: -moz-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -ms-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#b3b3b3), to(#808080));
  background-image: -webkit-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: -o-linear-gradient(to bottom, #b3b3b3, #808080);
  background-image: linear-gradient(to bottom, #b3b3b3, #808080);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b3b3b3', endColorstr='#808080', GradientType=0);
  border-color: #808080 #808080 #595959;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.selected:hover,
.datepicker table tr td.selected:hover:hover,
.datepicker table tr td.selected.disabled:hover,
.datepicker table tr td.selected.disabled:hover:hover,
.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active,
.datepicker table tr td.selected.disabled,
.datepicker table tr td.selected:hover.disabled,
.datepicker table tr td.selected.disabled.disabled,
.datepicker table tr td.selected.disabled:hover.disabled,
.datepicker table tr td.selected[disabled],
.datepicker table tr td.selected:hover[disabled],
.datepicker table tr td.selected.disabled[disabled],
.datepicker table tr td.selected.disabled:hover[disabled] {
  background-color: #808080;
}

.datepicker table tr td.selected:active,
.datepicker table tr td.selected:hover:active,
.datepicker table tr td.selected.disabled:active,
.datepicker table tr td.selected.disabled:hover:active,
.datepicker table tr td.selected.active,
.datepicker table tr td.selected:hover.active,
.datepicker table tr td.selected.disabled.active,
.datepicker table tr td.selected.disabled:hover.active {
  background-color: #666666 \9;
}

.datepicker table tr td.active,
.datepicker table tr td.active:hover,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active.disabled:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
  background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
  background-image: linear-gradient(to bottom, #08c, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td.active:hover,
.datepicker table tr td.active:hover:hover,
.datepicker table tr td.active.disabled:hover,
.datepicker table tr td.active.disabled:hover:hover,
.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active,
.datepicker table tr td.active.disabled,
.datepicker table tr td.active:hover.disabled,
.datepicker table tr td.active.disabled.disabled,
.datepicker table tr td.active.disabled:hover.disabled,
.datepicker table tr td.active[disabled],
.datepicker table tr td.active:hover[disabled],
.datepicker table tr td.active.disabled[disabled],
.datepicker table tr td.active.disabled:hover[disabled] {
  background-color: #0044cc;
}

.datepicker table tr td.active:active,
.datepicker table tr td.active:hover:active,
.datepicker table tr td.active.disabled:active,
.datepicker table tr td.active.disabled:hover:active,
.datepicker table tr td.active.active,
.datepicker table tr td.active:hover.active,
.datepicker table tr td.active.disabled.active,
.datepicker table tr td.active.disabled:hover.active {
  background-color: #003399 \9;
}

.datepicker table tr td span {
  display: block;
  width: 23%;
  height: 54px;
  line-height: 54px;
  float: left;
  margin: 1%;
  cursor: pointer;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.datepicker table tr td span:hover,
.datepicker table tr td span.focused {
  background: #eee;
}

.datepicker table tr td span.disabled,
.datepicker table tr td span.disabled:hover {
  background: none;
  color: #999;
  cursor: default;
}

.datepicker table tr td span.active,
.datepicker table tr td span.active:hover,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active.disabled:hover {
  background-color: #006dcc;
  background-image: -moz-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -ms-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#08c), to(#0044cc));
  background-image: -webkit-linear-gradient(to bottom, #08c, #0044cc);
  background-image: -o-linear-gradient(to bottom, #08c, #0044cc);
  background-image: linear-gradient(to bottom, #08c, #0044cc);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#08c', endColorstr='#0044cc', GradientType=0);
  border-color: #0044cc #0044cc #002a80;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.datepicker table tr td span.active:hover,
.datepicker table tr td span.active:hover:hover,
.datepicker table tr td span.active.disabled:hover,
.datepicker table tr td span.active.disabled:hover:hover,
.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active,
.datepicker table tr td span.active.disabled,
.datepicker table tr td span.active:hover.disabled,
.datepicker table tr td span.active.disabled.disabled,
.datepicker table tr td span.active.disabled:hover.disabled,
.datepicker table tr td span.active[disabled],
.datepicker table tr td span.active:hover[disabled],
.datepicker table tr td span.active.disabled[disabled],
.datepicker table tr td span.active.disabled:hover[disabled] {
  background-color: #0044cc;
}

.datepicker table tr td span.active:active,
.datepicker table tr td span.active:hover:active,
.datepicker table tr td span.active.disabled:active,
.datepicker table tr td span.active.disabled:hover:active,
.datepicker table tr td span.active.active,
.datepicker table tr td span.active:hover.active,
.datepicker table tr td span.active.disabled.active,
.datepicker table tr td span.active.disabled:hover.active {
  background-color: #003399 \9;
}

.datepicker table tr td span.old,
.datepicker table tr td span.new {
  color: #999;
}

.datepicker .datepicker-switch {
  width: 145px;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next,
.datepicker tfoot tr th {
  cursor: pointer;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover,
.datepicker tfoot tr th:hover {
  background: #eee;
}

.datepicker .prev.disabled,
.datepicker .next.disabled {
  visibility: hidden;
}

.datepicker .cw {
  font-size: 10px;
  width: 12px;
  padding: 0 2px 0 5px;
  vertical-align: middle;
}

.input-append.date .add-on,
.input-prepend.date .add-on {
  cursor: pointer;
}

.input-append.date .add-on i,
.input-prepend.date .add-on i {
  margin-top: 3px;
}

.input-daterange input {
  text-align: center;
}

.input-daterange input:first-child {
  -webkit-border-radius: 3px 0 0 3px;
  -moz-border-radius: 3px 0 0 3px;
  border-radius: 3px 0 0 3px;
}

.input-daterange input:last-child {
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

.input-daterange .add-on {
  display: inline-block;
  width: auto;
  min-width: 16px;
  height: 18px;
  padding: 4px 5px;
  font-weight: normal;
  line-height: 18px;
  text-align: center;
  text-shadow: 0 1px 0 #fff;
  vertical-align: middle;
  background-color: #eee;
  border: 1px solid #ccc;
  margin-left: -5px;
  margin-right: -5px;
}

/*# sourceMappingURL=bootstrap-datepicker.css.map */
.form-control {
  display: block;
  width: 100%;
  height: 3.25558rem;
  padding: 1.062rem 1.25rem 1.062rem;
  font-family: "Rebond Grotesque", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.13158;
  color: #000;
  background-color: #fff;
  background-clip: padding-box;
  border: solid #808080 1px;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out;
}

.frame-background-light .form-control {
  background-color: #fff;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

@media (min-width: 992px) {
  .form-control {
    padding: 1.062rem 1.25rem 1.062rem;
    height: 3.25558rem;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.form-control:focus {
  color: #000;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
}

.form-control::placeholder {
  color: #808080;
  font-style: italic;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #b8b8b8;
  opacity: 1;
}

.form-control:disabled {
  cursor: not-allowed;
}

.form-control[type="file"] {
  padding-top: 1.062rem;
}

@media (min-width: 992px) {
  .form-control[type="file"] {
    padding-top: 1.062rem;
  }
}

textarea.form-control {
  height: auto;
  min-height: 8rem;
  resize: vertical;
}

.form-group {
  margin-bottom: 3rem;
}

.form-set {
  margin-bottom: 3rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 8px;
  padding-left: 8px;
}

.was-validated .form-control:valid, .form-control.is-valid {
  padding-right: 2.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 512 512'%3e%3c!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license %28Commercial License%29 --%3e%3cpath fill='%23000' d='M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 1rem 1rem;
}

@media (min-width: 992px) {
  .was-validated .form-control:valid, .form-control.is-valid {
    padding-right: 2.5rem;
    background-position: right 1.25rem center;
  }
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.5rem;
  background-position: top 1.062rem right 1.25rem;
}

@media (min-width: 992px) {
  .was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: 2.5rem;
    background-position: top 1.062rem right 1.25rem;
  }
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  padding-right: calc(0.75em + 2.8125rem);
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  padding-right: 2.5rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 576 512'%3e%3c!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license %28Commercial License%29 --%3e%3cpath fill='%23d62929' d='M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 1rem 1rem;
  border-color: #d62929;
  background-color: rgba(214, 41, 41, 0.09);
}

@media (min-width: 992px) {
  .was-validated .form-control:invalid, .form-control.is-invalid {
    padding-right: 2.5rem;
    background-position: right 1.25rem center;
  }
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.5rem;
  background-position: top 1.062rem right 1.25rem;
}

@media (min-width: 992px) {
  .was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: 2.5rem;
    background-position: top 1.062rem right 1.25rem;
  }
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  padding-right: calc(0.75em + 2.8125rem);
  border-color: #d62929;
  background-color: rgba(214, 41, 41, 0.09);
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #d62929;
}

.form-control.input-validation-error,
.custom-select.input-validation-error {
  border-color: red;
  background-color: rgba(214, 41, 41, 0.09);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='25px' height='25px' viewBox='0 0 576 512'%3e%3c!-- Font Awesome Pro 5.15.3 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license %28Commercial License%29 --%3e%3cpath fill='%23d62929' d='M270.2 160h35.5c3.4 0 6.1 2.8 6 6.2l-7.5 196c-.1 3.2-2.8 5.8-6 5.8h-20.5c-3.2 0-5.9-2.5-6-5.8l-7.5-196c-.1-3.4 2.6-6.2 6-6.2zM288 388c-15.5 0-28 12.5-28 28s12.5 28 28 28 28-12.5 28-28-12.5-28-28-28zm281.5 52L329.6 24c-18.4-32-64.7-32-83.2 0L6.5 440c-18.4 31.9 4.6 72 41.6 72H528c36.8 0 60-40 41.5-72zM528 480H48c-12.3 0-20-13.3-13.9-24l240-416c6.1-10.6 21.6-10.7 27.7 0l240 416c6.2 10.6-1.5 24-13.8 24z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1.25rem center;
  background-size: 1rem 1rem;
}

label {
  font-size: 1rem;
  line-height: 1.13158;
}

.custom-control {
  position: relative;
  z-index: 1;
}

.custom-control .custom-control-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  z-index: -1;
}

.custom-control .custom-control-label {
  cursor: pointer;
  user-select: none;
  position: relative;
  padding-left: 2.875rem;
}

.custom-control .custom-control-label::before {
  position: absolute;
  content: "";
  display: block;
  top: 50%;
  margin-top: -0.9375rem;
  left: 0;
  height: 1.875rem;
  width: 1.875rem;
  background-color: #fff;
  border: solid 1px #808080;
}

.frame-background-dark .custom-control .custom-control-label::before {
  background-color: #fff;
}

.custom-control .custom-control-label::after {
  position: absolute;
  display: none;
  content: '';
  top: 50%;
  margin-top: -11px;
}

.custom-control:hover .custom-control-label::before {
  background-color: #fff;
}

.frame-background-dark .custom-control:hover .custom-control-label::before {
  background-color: #fff;
}

.custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: #fff;
}

.frame-background-dark .custom-control .custom-control-input:focus ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.frame-background-dark .custom-control .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #fff;
}

.custom-control .custom-control-input:checked ~ .custom-control-label::after {
  display: block;
}

.custom-control.custom-control-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-control.custom-control-checkbox .custom-control-label::after {
  width: 8px;
  height: 8px;
  left: 9px;
  content: '\00d7';
  font-size: 20px;
}

.custom-control.custom-control-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-control.custom-control-radio .custom-control-label::after {
  width: 22px;
  height: 22px;
  left: 4px;
  background-color: #d62929;
  border-radius: 50%;
}

label.form-group-label {
  font-weight: 500;
  font-size: 1.125rem;
  margin-bottom: 1rem;
}

label.form-group-label .required {
  color: #d62929;
}

.custom-control a {
  text-decoration: underline;
}

.custom-control.font-xs .custom-control-label {
  font-size: 1rem;
  line-height: 1.33333;
}

.custom-control.custom-control-block {
  width: 100%;
  padding-left: 0;
}

.custom-control.custom-control-block .custom-control-label {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.75rem 0.75rem 0.75rem 3.625rem;
  border-radius: 0;
  margin-bottom: 0;
}

.custom-control.custom-control-block.custom-control-checkbox .custom-control-label::after {
  left: 1.3125rem;
}

.newsletter-formular .custom-control-label::before,
.booking-form-container .custom-control-label::before {
  display: none;
}

.newsletter-formular .control--indicator,
.booking-form-container .control--indicator {
  position: absolute;
  top: -8px;
  left: 0;
  height: 30px;
  width: 30px;
  background: #fff;
  border: 1px solid #808080;
}

.newsletter-formular .control--indicator:after,
.booking-form-container .control--indicator:after {
  content: '\00d7';
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  height: calc(100% - 1px);
  width: calc(100% - 1px);
  color: #000;
  font-size: 20px;
}

.newsletter-formular .control input:checked ~ .control--indicator,
.booking-form-container .control input:checked ~ .control--indicator {
  background: #fff;
}

.newsletter-formular .control input:checked ~ .control--indicator:after,
.booking-form-container .control input:checked ~ .control--indicator:after {
  display: flex;
}

.newsletter-formular .control:hover input:not([disabled]):checked ~ .control--indicator,
.newsletter-formular .control input:checked:focus ~ .control--indicator,
.booking-form-container .control:hover input:not([disabled]):checked ~ .control--indicator,
.booking-form-container .control input:checked:focus ~ .control--indicator {
  background: #fff;
}

.newsletter-formular .control input:disabled ~ .control--indicator,
.booking-form-container .control input:disabled ~ .control--indicator {
  background: #fff;
  opacity: 0.6;
  pointer-events: none;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: 3.25558rem;
  padding: 1.062rem 1.25rem 1.062rem;
  font-family: "Rebond Grotesque", Arial, sans-serif;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: 1.13158;
  color: #808080;
  font-style: italic;
  vertical-align: middle;
  background: #fff url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/FontAwesome/light/angle-down.svg") no-repeat right;
  background-position-x: calc(100% - 11px);
  background-size: 30px;
  border: solid #808080 1px;
  border-radius: 0;
  appearance: none;
}

@media (min-width: 992px) {
  .custom-select {
    padding: 1.062rem 1.25rem 1.062rem;
    height: 3.25558rem;
  }
}

.custom-select option:first-child {
  color: #808080;
  font-style: italic;
}

.custom-select:focus {
  color: #000;
  background-color: #fff;
  outline: 0;
  box-shadow: none;
}

.custom-select:focus::-ms-value {
  color: #000;
  background-color: #fff;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

.form-group-select {
  position: relative;
}

.form-group-select label {
  position: absolute;
  left: 1.25rem;
  top: 0.25rem;
  color: #000;
  font-size: 0.625rem;
}

@media (min-width: 992px) {
  .form-group-select label {
    left: 1.25rem;
    top: 0.5rem;
  }
}

.help-block {
  font-size: 80%;
  line-height: 1.33333;
  display: inline-block;
  color: red;
}

.form-ce p {
  line-height: 1.3;
}

input.datepicker {
  background-image: url("/typo3conf/ext/wvier_speciality/Resources/Public/Assets/Production/Images/FontAwesome/light/calendar.svg");
  background-size: 1rem;
  background-position: right 1rem center;
  background-repeat: no-repeat;
}

.datepicker.dropdown-menu {
  background-color: #fff;
  color: #000;
  border: solid 1px #000;
  border-radius: 0;
}

.datepicker.dropdown-menu th,
.datepicker.dropdown-menu td {
  padding: 3px;
}

.datepicker.dropdown-menu th.active,
.datepicker.dropdown-menu td.active {
  background-color: #00207a !important;
  background-image: none;
}

.form-control[readonly] {
  background-color: #fff;
}

.tx-wvier-saunaaufgussplan.container-fluid {
  padding: 0;
}

.tx-wvier-saunaaufgussplan .table {
  margin-bottom: 14px;
}

.tx-wvier-saunaaufgussplan .table thead tr td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tx-wvier-saunaaufgussplan .table tbody tr td {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.tx-wvier-saunaaufgussplan .table tbody tr td p {
  margin-bottom: 0;
  text-align: left;
}

.tx-wvier-saunaaufgussplan .table tbody tr td:first-child {
  width: 100px;
  text-align: center;
}

.tx-wvier-saunaaufgussplan .table tbody tr.fallbacktext td:first-child {
  text-align: left;
  border-bottom: 0;
}

.tx-wvier-saunaaufgussplan .table.other-sauna tbody td {
  border-bottom: 0;
}

main:not(.infusionplan) .tx-wvier-saunaaufgussplan .table {
  margin-bottom: 40px;
}

@media (min-width: 992px) {
  main:not(.infusionplan) .tx-wvier-saunaaufgussplan .table {
    margin-bottom: 50px;
  }
}

main:not(.infusionplan) .tx-wvier-saunaaufgussplan .table thead td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

main:not(.infusionplan) .tx-wvier-saunaaufgussplan .table thead strong {
  font-size: 1.5rem;
  margin-bottom: 0.625rem;
  line-height: 1.1;
}

@media (min-width: 992px) {
  main:not(.infusionplan) .tx-wvier-saunaaufgussplan .table thead strong {
    font-size: 1.625rem;
  }
}

main:not(.infusionplan) .tx-wvier-saunaaufgussplan .table thead strong .smallsubtitle {
  font-size: 1rem;
}

main:not(.infusionplan) .tx-wvier-saunaaufgussplan .table tbody td {
  font-size: 1.125rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
}

@media (min-width: 992px) {
  main:not(.infusionplan) .tx-wvier-saunaaufgussplan .other-saunas {
    flex-wrap: wrap;
  }
  main:not(.infusionplan) .tx-wvier-saunaaufgussplan .other-saunas .table-responsive {
    padding-left: 15px;
    padding-right: 15px;
    width: 25%;
  }
  main:not(.infusionplan) .tx-wvier-saunaaufgussplan .other-saunas .table-responsive:first-child {
    padding-right: 15px;
    padding-left: 0;
  }
  main:not(.infusionplan) .tx-wvier-saunaaufgussplan .other-saunas .table-responsive:last-child {
    padding-left: 15px;
    padding-right: 0;
  }
  main:not(.infusionplan) .tx-wvier-saunaaufgussplan .other-saunas .table-responsive p {
    font-size: 1.125rem;
  }
}

.infusionplan .container {
  margin-left: 0;
}

.infusionplan .frame {
  margin: 15px;
}

.infusionplan .frame h2 {
  margin-bottom: 0.5rem;
}

.infusionplan .frame h2 {
  font-size: 2rem;
}

@media (min-width: 1920px) {
  .infusionplan .frame h2 {
    font-size: calc(2rem + 4 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .infusionplan .frame h2 {
    font-size: 6rem;
  }
}

.infusionplan .frame .col-lg-4:first-child,
.infusionplan .frame .col-lg-8:first-child {
  padding-left: 0;
}

.infusionplan .frame .col-lg-4:last-child,
.infusionplan .frame .col-lg-8:last-child {
  padding-right: 0;
}

.tx-wvier-saunaaufgussplan.container-fluid {
  padding: 0;
}

.tx-wvier-saunaaufgussplan .row {
  margin: 0;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table {
  margin-bottom: 14px;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table thead tr td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table thead tr td {
  font-size: 1.375rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table thead tr td {
    font-size: calc(1.375rem + 2.325 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table thead tr td {
    font-size: 3.7rem;
  }
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table thead tr td .smallsubtitle {
  font-size: 0.6875rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table thead tr td .smallsubtitle {
    font-size: calc(0.6875rem + 1.3125 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table thead tr td .smallsubtitle {
    font-size: 2rem;
  }
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td {
  line-height: 1;
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td {
  font-size: 0.9rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td {
    font-size: calc(0.9rem + 1.77 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td {
    font-size: 2.67rem;
  }
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td p {
  line-height: 1.2;
  margin-bottom: 0;
  text-align: left;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td p {
  font-size: 1rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td p {
    font-size: calc(1rem + 1.67 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td p {
    font-size: 2.67rem;
  }
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr td:first-child {
  width: 100px;
  text-align: left;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table tbody tr.fallbacktext td:first-child {
  text-align: left;
  border-bottom: 0;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table.other-sauna {
  margin-bottom: 0;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .table.other-sauna tbody td {
  border-bottom: 0;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .bergkristall-2col tbody {
  columns: 2;
  display: block;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .bergkristall-2col tbody.fallbacktextbody {
  columns: 1 !important;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .bergkristall-2col tbody tr {
  width: 100%;
  display: flex;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .bergkristall-2col tbody tr td:first-child {
  display: block;
  flex-shrink: 0;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .bergkristall-2col tbody tr td:last-child {
  width: 100%;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .andere_saunas_indoor {
  display: flex;
  align-items: flex-start;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .andere_saunas_indoor .table-responsive:first-child {
  margin-right: 30px;
}

.tx-wvier-saunaaufgussplan .aufgussplan-splitscreen .andere_saunas_indoor .table-responsive:last-child {
  margin-left: 30px;
}

.tx-wvier-saunaaufgussplan .table {
  margin-bottom: 14px;
}

.tx-wvier-saunaaufgussplan .table thead tr td {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.tx-wvier-saunaaufgussplan .table thead tr td {
  font-size: 1.375rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .table thead tr td {
    font-size: calc(1.375rem + 1.625 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .table thead tr td {
    font-size: 3rem;
  }
}

.tx-wvier-saunaaufgussplan .table thead tr td .smallsubtitle {
  font-size: 0.6875rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .table thead tr td .smallsubtitle {
    font-size: calc(0.6875rem + 0.6875 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .table thead tr td .smallsubtitle {
    font-size: 1.375rem;
  }
}

.tx-wvier-saunaaufgussplan .table tbody tr td {
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
}

.tx-wvier-saunaaufgussplan .table tbody tr td {
  font-size: 0.9rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .table tbody tr td {
    font-size: calc(0.9rem + 1.1 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .table tbody tr td {
    font-size: 2rem;
  }
}

.tx-wvier-saunaaufgussplan .table tbody tr td p {
  margin-bottom: 0;
  text-align: left;
}

.tx-wvier-saunaaufgussplan .table tbody tr td p {
  font-size: 1rem;
}

@media (min-width: 1920px) {
  .tx-wvier-saunaaufgussplan .table tbody tr td p {
    font-size: calc(1rem + 1 * ((100vw - 120rem) / 37.75));
  }
}

@media (min-width: 2524px) {
  .tx-wvier-saunaaufgussplan .table tbody tr td p {
    font-size: 2rem;
  }
}

.tx-wvier-saunaaufgussplan .table tbody tr td:first-child {
  width: 100px;
  text-align: center;
}

.tx-wvier-saunaaufgussplan .table tbody tr.fallbacktext td:first-child {
  text-align: left;
  border-bottom: 0;
}

.tx-wvier-saunaaufgussplan .table.other-sauna {
  margin-bottom: 0;
}

.tx-wvier-saunaaufgussplan .table.other-sauna tbody td {
  border-bottom: 0;
}

@media (min-width: 1920px) {
  .infusionplan {
    max-width: 100%;
  }
  .infusionplan .frame {
    margin: 30px;
  }
  .infusionplan .frame h2 {
    margin-bottom: 1.1rem;
  }
  .infusionplan .frame .aufgussplan-splitscreen.aufgussplan-outdoor {
    margin-top: 100px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4:first-child,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8:first-child {
    padding-left: 0;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4:last-child,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8:last-child {
    padding-right: 0;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4 .table,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 .table {
    margin-bottom: 50px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4 .table thead tr td,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 .table thead tr td {
    padding: 1rem;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4 .table tbody tr td,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 .table tbody tr td {
    padding: 1rem;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody {
    columns: 2;
    display: block;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody tr {
    width: 100%;
    display: flex;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody tr td:first-child {
    width: 180px;
    display: block;
    flex-shrink: 0;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody tr td:last-child {
    width: 100%;
  }
  .infusionplan .frame .aufgussplan-splitscreen .andere_saunas_indoor {
    display: flex;
    align-items: flex-start;
  }
  .infusionplan .frame .aufgussplan-splitscreen .andere_saunas_indoor .table-responsive:first-child {
    margin-right: 30px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .andere_saunas_indoor .table-responsive:last-child {
    margin-left: 30px;
  }
  .infusionplan .frame .col-lg-4,
  .infusionplan .frame .col-lg-8 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .infusionplan .frame .col-lg-4:first-child,
  .infusionplan .frame .col-lg-8:first-child {
    padding-left: 0;
  }
  .infusionplan .frame .col-lg-4:last-child,
  .infusionplan .frame .col-lg-8:last-child {
    padding-right: 0;
  }
  .infusionplan .frame .col-lg-4 .table,
  .infusionplan .frame .col-lg-8 .table {
    margin-bottom: 25px;
  }
  .infusionplan .frame .col-lg-4 .table thead tr td,
  .infusionplan .frame .col-lg-8 .table thead tr td {
    padding-top: 0;
    padding-bottom: 0.5rem;
  }
  .infusionplan .frame .col-lg-4 .table tbody tr td,
  .infusionplan .frame .col-lg-8 .table tbody tr td {
    padding-bottom: 0.375rem;
    padding-top: 0.375rem;
  }
  .infusionplan .frame .col-lg-4 .table tbody tr td:first-child,
  .infusionplan .frame .col-lg-8 .table tbody tr td:first-child {
    width: 150px;
  }
  .infusionplan .frame .fallback .sauna-tables .table {
    margin-bottom: 40px;
  }
  .infusionplan .frame .fallback .sauna-tables .table .fallbacktext td {
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 0;
  }
}

@media (min-width: 2524px) {
  .infusionplan {
    max-height: 100vh;
    overflow: hidden;
  }
  .infusionplan .frame {
    margin: 60px;
  }
  .infusionplan .frame h2 {
    margin-bottom: 3rem;
  }
  .infusionplan .frame .aufgussplan-splitscreen.aufgussplan-outdoor {
    margin-top: 100px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4:first-child,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8:first-child {
    padding-left: 0;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4:last-child,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8:last-child {
    padding-right: 0;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4 .table,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 .table {
    margin-bottom: 50px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4 .table thead tr td,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 .table thead tr td {
    padding: 1.6rem;
  }
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-4 .table tbody tr td,
  .infusionplan .frame .aufgussplan-splitscreen .col-lg-8 .table tbody tr td {
    padding: 1.6rem;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody {
    columns: 2;
    display: block;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody tr {
    width: 100%;
    display: flex;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody tr td:first-child {
    width: 180px;
    display: block;
    flex-shrink: 0;
  }
  .infusionplan .frame .aufgussplan-splitscreen .bergkristall-2col tbody tr td:last-child {
    width: 100%;
  }
  .infusionplan .frame .aufgussplan-splitscreen .andere_saunas_indoor {
    display: flex;
    align-items: flex-start;
  }
  .infusionplan .frame .aufgussplan-splitscreen .andere_saunas_indoor .table-responsive:first-child {
    margin-right: 30px;
  }
  .infusionplan .frame .aufgussplan-splitscreen .andere_saunas_indoor .table-responsive:last-child {
    margin-left: 30px;
  }
  .infusionplan .frame .col-lg-4,
  .infusionplan .frame .col-lg-8 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .infusionplan .frame .col-lg-4:first-child,
  .infusionplan .frame .col-lg-8:first-child {
    padding-left: 0;
  }
  .infusionplan .frame .col-lg-4:last-child,
  .infusionplan .frame .col-lg-8:last-child {
    padding-right: 0;
  }
  .infusionplan .frame .col-lg-4 .table,
  .infusionplan .frame .col-lg-8 .table {
    margin-bottom: 50px;
  }
  .infusionplan .frame .col-lg-4 .table thead tr td,
  .infusionplan .frame .col-lg-8 .table thead tr td {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .infusionplan .frame .col-lg-4 .table tbody tr td,
  .infusionplan .frame .col-lg-8 .table tbody tr td {
    padding-bottom: 0.375rem;
    padding-top: 0.375rem;
  }
  .infusionplan .frame .fallback .sauna-tables .table {
    margin-bottom: 100px;
  }
  .infusionplan .frame .fallback .sauna-tables .table .fallbacktext td {
    padding-top: 30px;
    padding-bottom: 30px;
    border-bottom: 0;
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid transparent;
}

.card-footer {
  padding: 1rem 1rem;
  background-color: #fff;
  border-top: 0 solid transparent;
}

.card-img,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
}

/*
// Card deck

.card-deck {
  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}*/
.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
}

.accordion > .card > .card-header {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .card-body {
    padding: 1.75rem;
  }
}

.card-group-element {
  margin: -12px;
  margin-bottom: -12px !important;
  display: flex;
  flex-wrap: wrap;
}

.card-group-element-item {
  width: 100%;
  padding: 12px;
}

.card-group-element-item .card {
  height: 100%;
}

.card-group-element-item .card-title {
  margin-bottom: 1rem;
}

.card-group-element-item .card-subtitle {
  margin-bottom: 1rem;
}

.card-group-element-item .card-body {
  background: #fff;
}

.card-group-element-item .card-title + .card-subtitle {
  margin-top: -1rem;
}

.card-group-element-item .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 0;
  padding: 1rem;
  background-color: transparent;
  flex-wrap: wrap;
}

.card-group-element-item .card-footer .btn-link {
  white-space: nowrap;
}

@media (min-width: 992px) {
  .card-group-element-item .card-body {
    padding: 1.75rem 1.75rem 0.4375rem;
  }
  .card-group-element-item .card-footer {
    padding: 0.875rem 1.75rem;
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-2 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 992px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: calc(100% / 3);
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 1374px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 4);
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
}

.card > hr {
  margin-right: 0;
  margin-left: 0;
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}

.card > .list-group:first-child {
  border-top-width: 0;
}

.card > .list-group:last-child {
  border-bottom-width: 0;
}

.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1rem;
}

.card-title {
  margin-bottom: 1rem;
}

.card-subtitle {
  margin-top: -0.5rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}

.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 1rem 1rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 0 solid transparent;
}

.card-footer {
  padding: 1rem 1rem;
  background-color: #fff;
  border-top: 0 solid transparent;
}

.card-img,
.card-img-top {
  flex-shrink: 0;
  width: 100%;
}

/*
// Card deck

.card-deck {
  .card {
    margin-bottom: $card-deck-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    margin-right: -$card-deck-margin;
    margin-left: -$card-deck-margin;

    .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-right: $card-deck-margin;
      margin-bottom: 0; // Override the default
      margin-left: $card-deck-margin;
    }
  }
}


//
// Card groups
//

.card-group {
  // The child selector allows nested `.card` within `.card-group`
  // to display properly.
  > .card {
    margin-bottom: $card-group-margin;
  }

  @include media-breakpoint-up(sm) {
    display: flex;
    flex-flow: row wrap;
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
      // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
      flex: 1 0 0%;
      margin-bottom: 0;

      + .card {
        margin-left: 0;
        border-left: 0;
      }

      // Handle rounded corners
      @if $enable-rounded {
        &:not(:last-child) {
          @include border-right-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-right-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-right-radius: 0;
          }
        }

        &:not(:first-child) {
          @include border-left-radius(0);

          .card-img-top,
          .card-header {
            // stylelint-disable-next-line property-disallowed-list
            border-top-left-radius: 0;
          }
          .card-img-bottom,
          .card-footer {
            // stylelint-disable-next-line property-disallowed-list
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
}


//
// Columns
//

.card-columns {
  .card {
    margin-bottom: $card-columns-margin;
  }

  @include media-breakpoint-up(sm) {
    column-count: $card-columns-count;
    column-gap: $card-columns-gap;
    orphans: 1;
    widows: 1;

    .card {
      display: inline-block; // Don't let them vertically span multiple columns
      width: 100%; // Don't let their width change
    }
  }
}*/
.accordion {
  overflow-anchor: none;
}

.accordion > .card {
  overflow: hidden;
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
}

.accordion > .card > .card-header {
  margin-bottom: 0;
}

@media (min-width: 992px) {
  .card-body {
    padding: 1.75rem;
  }
}

.card-group-element {
  margin: -12px;
  margin-bottom: -12px !important;
  display: flex;
  flex-wrap: wrap;
}

.card-group-element-item {
  width: 100%;
  padding: 12px;
}

.card-group-element-item .card {
  height: 100%;
}

.card-group-element-item .card-title {
  margin-bottom: 1rem;
}

.card-group-element-item .card-subtitle {
  margin-bottom: 1rem;
}

.card-group-element-item .card-body {
  background: #fff;
}

.card-group-element-item .card-title + .card-subtitle {
  margin-top: -1rem;
}

.card-group-element-item .card-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 0;
  padding: 1rem;
  background-color: transparent;
  flex-wrap: wrap;
}

.card-group-element-item .card-footer .btn-link {
  white-space: nowrap;
}

@media (min-width: 992px) {
  .card-group-element-item .card-body {
    padding: 1.75rem 1.75rem 0.4375rem;
  }
  .card-group-element-item .card-footer {
    padding: 0.875rem 1.75rem;
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-2 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 992px) {
  .card-group-element-columns-3 .card-group-element-item {
    width: calc(100% / 3);
  }
}

@media (min-width: 576px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 2);
  }
}

@media (min-width: 1374px) {
  .card-group-element-columns-4 .card-group-element-item {
    width: calc(100% / 4);
  }
}

.top-offers-content .card-group-element-item {
  height: 100%;
  padding: 0;
}

.top-offers-content .readmore-wrapper {
  margin-top: 1.125rem;
}

@media (max-width: 440px) {
  .top-offers-content .readmore-wrapper .btn:not(:last-of-type) {
    margin-left: 1.9rem;
  }
}

@media (min-width: 992px) {
  .top-offers-content .readmore-wrapper {
    margin-top: 2.375rem;
  }
}

.top-offers-content .card-img-top {
  position: relative;
}

.top-offers-content .card-img-top img {
  width: 100%;
}

.top-offers-content .card-img-top .ribbon {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 250px;
  display: block;
  width: 100%;
  text-align: center;
  color: white;
  padding: 10px 0;
}

.top-offers-content .card-img-top .ribbon span {
  font-weight: 400;
  font-size: 1.125rem;
}

.btn.infopopup-btn {
  position: fixed;
  left: 10px;
  bottom: 70px;
  display: block;
  transform: rotate(-10deg);
  width: 65px;
  height: 65px;
  padding: 10px;
  border: 0;
  border-radius: 50%;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  z-index: 900;
  appearance: none;
  pointer-events: auto;
}

.btn.infopopup-btn:focus, .btn.infopopup-btn.focus {
  outline-color: #404040;
}

.btn.infopopup-btn .svg-icon {
  width: 100%;
  height: 100%;
}

@media (min-width: 992px) {
  .btn.infopopup-btn {
    width: 80px;
    height: 80px;
    padding: 14px;
    bottom: 10px;
  }
}

.infopopup-items-wrapper {
  position: fixed;
  display: flex;
  align-items: flex-end;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 75px;
  z-index: 900;
  pointer-events: none;
}

.infopopup-items-wrapper.show-it .infopopup-inner {
  animation-name: infoIn;
  animation-duration: 250ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.infopopup-items-wrapper.hide-it .infopopup-inner {
  animation-name: infoOut;
  animation-duration: 250ms;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

.infopopup-items-wrapper .infopopup-inner {
  position: relative;
  padding: 3.25rem 1.25rem 1.25rem;
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  pointer-events: auto;
}

@media (min-width: 576px) {
  .infopopup-items-wrapper {
    right: auto;
    width: 430px;
  }
}

@media (min-width: 992px) {
  .infopopup-items-wrapper {
    left: 25px;
    bottom: 25px;
  }
  .infopopup-items-wrapper .infopopup-inner {
    padding: 3.25rem 2.25rem 2.25rem;
  }
}

.infopopup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 26px;
  height: 26px;
  display: block;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;
}

.infopopup-close:focus, .infopopup-close.focus {
  outline-color: #404040;
}

.infopopup-close::before, .infopopup-close::after {
  content: '';
  position: absolute;
  left: 12px;
  top: 0;
  height: 26px;
  width: 1px;
  background-color: #000;
}

.infopopup-close::before {
  transform: rotate(45deg);
}

.infopopup-close::after {
  transform: rotate(-45deg);
}

@keyframes infoIn {
  0% {
    opacity: 0;
    transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes infoOut {
  0% {
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
  100% {
    opacity: 0;
    transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
  }
}

.container .container {
  padding: 0;
}

.bodytext .btn {
  margin-top: 1.9rem;
  margin-bottom: 0.95rem;
}

.bodytext .btn:not(:last-of-type) {
  margin-right: 1.9rem;
}

[tabindex]:not([tabindex="-1"]):focus,
a:focus-visible,
button:focus-visible,
input:focus-visible,
select:focus:focus-visible,
textarea:focus-visible {
  outline-color: currentColor;
  outline-width: 1px;
  outline-offset: -3px;
  outline-style: dotted;
}

.navbar-brand-wrapper .dropdown-menu {
  margin: 0;
  padding: 0;
  border: 0;
  width: 170px;
}

@media (min-width: 992px) {
  .navbar-brand-wrapper .dropdown-menu {
    width: 254px;
  }
}

.resort-dropdown-item {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  height: 60px;
  font-size: 1rem;
}

.resort-dropdown-item:hover {
  color: #d9d9d9;
}

@media (min-width: 992px) {
  .resort-dropdown-item {
    font-size: 1.375rem;
    height: 90px;
  }
}

@media (min-width: 992px) {
  .screen-overlay {
    position: fixed;
    visibility: visible;
    top: 90px;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    pointer-events: none;
    z-index: 1029;
    opacity: 1;
  }
}

.blue {
  color: #00207a;
}

.red {
  color: #d62929;
}

.orange {
  color: #f47a4a;
}

.green {
  color: #6da852;
}

.skiplinks .sr-only:focus {
  display: inline-block;
  padding: 0.5rem;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  background-color: #fff;
  z-index: 1070;
}

.cross-icon {
  position: relative;
}

.cross-icon::before, .cross-icon::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: 2px;
  width: 100%;
  background-color: currentColor;
  transition: all 0.2s ease-in-out;
}

.cross-icon::before {
  transform: rotate(-45deg);
}

.cross-icon::after {
  transform: rotate(45deg);
}

.collapse-icon {
  position: absolute;
  width: 0.875rem;
  height: 0.875rem;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.collapsed > .collapse-icon::before {
  transform: rotate(0);
}

.collapsed > .collapse-icon::after {
  transform: rotate(90deg);
}

.dropdown-toggle > .collapse-icon::before {
  transform: rotate(0);
}

.dropdown-toggle > .collapse-icon::after {
  transform: rotate(90deg);
}

.show > .dropdown-toggle > .collapse-icon::before {
  transform: rotate(-45deg);
}

.show > .dropdown-toggle > .collapse-icon::after {
  transform: rotate(45deg);
}

@media (min-width: 992px) {
  .navbar-main .navbar-nav .nav-link {
    display: inline-block;
  }
  .navbar-main .navbar-nav .nav-link::before {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }
  .navbar-main .navbar-nav .nav-link:hover {
    font-weight: 500;
  }
  .navbar-main .navbar-nav .nav-item.dropdown.show .nav-link:hover {
    font-weight: 500;
  }
}

.main-content ul li:not(.splide__slide) + li:not(.splide__slide),
.main-content ol li:not(.splide__slide) + li:not(.splide__slide) {
  margin-top: 0.625rem;
}

.footer {
  line-height: 1.5;
}

.footer a:hover {
  color: #ccc !important;
}

@media (max-width: 991.98px) {
  .footer {
    /* make space for fixed cta-row */
    padding-bottom: 3.75rem;
  }
}

.footer .nav-title {
  font-weight: 500;
  font-size: 1.125rem;
  padding: 0.75rem 0;
  line-height: 1.2222;
}

@media (min-width: 768px) {
  .footer .nav-title {
    margin-bottom: 1.125rem;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .footer .nav-title {
    font-size: 1.375rem;
  }
}

.footer .sociallink {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  width: 3.25rem;
  height: 3.25rem;
  border-radius: 1.625rem;
  font-size: 1rem;
  margin-right: 0.75rem;
}

.footer .sociallink:last-child {
  margin-right: 0;
}

.footer .sociallink:hover {
  border: 2px solid;
}

@media (max-width: 767.98px) {
  .footer .footer-nav:not(.last) {
    border-bottom: 1px solid #f6f6f6;
  }
  .footer .footer-nav .nav-title {
    position: relative;
    padding-right: 1rem;
  }
}

.footer .footer-nav .list-unstyled {
  margin-bottom: 0.75rem;
  margin-left: 2rem;
}

.footer .footer-nav .list-unstyled li {
  margin-bottom: 0.6rem;
}

.footer .footer-nav .list-unstyled li a {
  line-height: 1.1;
  display: block;
}

@media (min-width: 768px) {
  .footer .footer-nav .list-unstyled {
    margin-bottom: 0;
    margin-left: 0;
  }
}

.footer .footer-logos-left a {
  width: 100%;
}

.footer .footer-logos-left img {
  width: 100%;
  height: auto;
}

.footer .footer-svg-link {
  display: block;
  width: 150px;
  max-width: 100%;
  margin: 0 auto;
}

@media (min-width: 1374px) {
  .footer .footer-svg-link {
    width: 140px;
  }
}

.footer .footer-svg-link svg {
  max-width: 100%;
  height: auto;
}

.footer .footer-svg-link.footer-svg-left {
  display: flex;
  justify-content: center;
}

.footer .footer-svg-link.footer-svg-left.footer-svg-grey {
  filter: grayscale(1);
}

.footer .footer-svg-link.footer-svg-left.footer-svg-grey:hover {
  filter: none;
}

@media (min-width: 768px) {
  .footer .footer-svg-link {
    margin: 0;
  }
}

@media (min-width: 1374px) {
  .footer .footer-svg-link {
    margin: 0 12px;
  }
  .footer .footer-svg-link.footer-svg-left {
    justify-content: flex-start;
    margin-left: 0;
  }
}

.occupancy-item {
  padding: 20px 0;
  width: 70%;
  margin: auto;
}

@media (max-width: 991.98px) {
  .occupancy-item {
    width: 45% !important;
  }
}

.occupancy-item .occupancy-values-container {
  position: relative;
  padding-left: 2px;
  padding-right: 2px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.occupancy-item .occupancy-values-row {
  margin-left: -4px;
  margin-right: -4px;
  overflow: hidden;
}

.occupancy-item .occupancy-values-row .occupancy-values-item .base {
  position: absolute;
  left: 2px;
  right: 2px;
  bottom: 0;
  display: block;
  height: 1px;
  background-color: #dee1e4;
}

.occupancy-item .occupancy-values-row .occupancy-values-item {
  float: left;
  position: relative;
  width: 16.667%;
  height: 48px;
  padding-left: 2px;
  padding-right: 2px;
  opacity: 0.2;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.active {
  opacity: 1;
}

.occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle {
  width: 100%;
  height: 0;
  padding-top: 8px;
  overflow: hidden;
  position: absolute;
  top: -8px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  margin-top: -8px;
  border-top: 8px solid transparent;
  border-right: 50px solid #dee1e4;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-2 .base {
  height: 8px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-3 .base {
  height: 16px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-4 .base {
  height: 24px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-5 .base {
  height: 32px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-6 > span {
  height: 40px;
}

.occupancy-item .occupancy-values-container .occupancy-tooltip-container {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
}

.occupancy-schallerbach .occupancy-item .occupancy-values-row .occupancy-values-item .base {
  background-color: #d62929;
}

.occupancy-schallerbach .occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
  border-right: 50px solid #d62929;
}

.occupancy-hall .occupancy-item .occupancy-values-row .occupancy-values-item .base {
  background-color: #6da852;
}

.occupancy-hall .occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
  border-right: 50px solid #6da852;
}

.occupancy-ischl .occupancy-item .occupancy-values-row .occupancy-values-item .base {
  background-color: #f47a4a;
}

.occupancy-ischl .occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
  border-right: 50px solid #f47a4a;
}

.occupancy-title .btn-link {
  color: #000;
}

.occupancy-title .btn-link:hover h5 {
  font-weight: bold;
}
