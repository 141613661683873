// Gallery

.gallery-row {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$grid-gutter-width/2;
    margin-left: -$grid-gutter-width/2;
    margin-right: -$grid-gutter-width/2;
    margin-bottom: -$grid-gutter-width/2;

    @include media-breakpoint-up(lg) {
        margin-top: -$grid-gutter-width-lg/2;
        margin-left: -$grid-gutter-width-lg/2;
        margin-right: -$grid-gutter-width-lg/2;
        margin-bottom: -$grid-gutter-width-lg/2;
    }
}
.gallery-item {
    padding-top: $grid-gutter-width/2;
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;

    @include media-breakpoint-up(lg) {
        padding-top: $grid-gutter-width-lg/2;
        padding-left: $grid-gutter-width-lg/2;
        padding-right: $grid-gutter-width-lg/2;
        padding-bottom: $grid-gutter-width-lg/2;
    }
}

figure.image {
    margin: 0;

    img,
    picture {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        height: auto;
    }
}


// Gallery Item Sizes

.gallery-item-size-1 {
    width: 100%;
}
.gallery-item-size-2 {
    width: 100%;

    @include media-breakpoint-up('sm') {
        width: calc(100% / 2);
    }
}
.gallery-item-size-3 {
    width: 100%;

    @include media-breakpoint-up('sm') {
        width: calc(100% / 2);
    }

    @include media-breakpoint-up('md') {
        width: calc(100% / 3);
    }
}
.gallery-item-size-4 {
    width: calc(100% / 2);
    @include media-breakpoint-up('sm') {
        width: calc(100% / 4);
    }
}

