@import "variables";

//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

//Customize colors
@import "colors";

//Bootstrap Optional
@import "bootstrap/scss/images";
//@import "bootstrap/scss/code";
//@import "bootstrap/scss/tables";
//@import "bootstrap/scss/forms";
//@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
//@import "bootstrap/scss/dropdown";
//@import "bootstrap/scss/button-group";
//@import "bootstrap/scss/input-group";
//@import "bootstrap/scss/custom-forms";
//@import "bootstrap/scss/nav";
//@import "bootstrap/scss/navbar";
//@import "bootstrap/scss/card";
//@import "bootstrap/scss/breadcrumb";
//@import "bootstrap/scss/pagination";
//@import "bootstrap/scss/badge";
//@import "bootstrap/scss/jumbotron";
//@import "bootstrap/scss/alert";
//@import "bootstrap/scss/progress";
//@import "bootstrap/scss/media";
//@import "bootstrap/scss/list-group";
//@import "bootstrap/scss/close";
//@import "bootstrap/scss/toasts";
//@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
//@import "bootstrap/scss/popover";
//@import "bootstrap/scss/carousel";
//@import "bootstrap/scss/spinners";
//@import "bootstrap/scss/utilities";
//@import "bootstrap/scss/print";

// Custom Mixins
@import "mixins";

////Bootstrap Custom
//@import "BootstrapCustom/type";
//@import "BootstrapCustom/buttons";
@import "BootstrapCustom/tables";

// Custom Stuff
//@import "navbar";
//@import "frame";
//@import "infoteaser";
//@import "textpic";
//@import "texticon";
@import "gallery";
@import "teaser";
@import "magnific";
@import "news";
@import "forms";
@import "saunaaufgussplan";
@import "card";
@import "topoffers";
@import "infopopup";
//@import "uploads";

.container {
    .container {
        padding: 0;
    }
}

.bodytext {
    .btn {
        margin-top: 1.9rem;
        margin-bottom: 0.95rem;
    }

    .btn:not(:last-of-type) {
        margin-right: 1.9rem;
    }
}

[tabindex]:not([tabindex="-1"]):focus,
a:focus-visible,
button:focus-visible,
input:focus-visible,
select:focus:focus-visible,
textarea:focus-visible {
    outline-color: currentColor;
    outline-width: 1px;
    outline-offset: -3px;
    outline-style: dotted;
}

// Resort Dropdown
.navbar-brand-wrapper {
    .dropdown-menu {
        margin: 0;
        padding: 0;
        border: 0;
        width: 170px;

        @include media-breakpoint-up(lg) {
            width: 254px;
        }
    }
}

.resort-dropdown-item {
    color: $link-alternate-color;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    height: 60px;
    font-size: 1rem;

    @include hover() {
        color: $link-alternate-hover-color;
    }

    @include media-breakpoint-up(lg) {
        font-size: 1.375rem;
        height: 90px;
    }
}

.screen-overlay{
    @include media-breakpoint-up(lg) {
        position: fixed;
        visibility: visible;
        top: 90px;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba($black, 0.4);
        pointer-events: none;
        z-index: ($zindex-fixed - 1);
        opacity: 1;
    }
}

//RTE Text Color
.blue {
    color: $blue;
}

.red {
    color: $red;
}

.orange {
    color: $orange;
}

.green {
    color: $green;
}

// Skiplinks
.skiplinks {
    .sr-only {
        &:focus {
            display: inline-block;
            padding: 0.5rem;
            width: auto;
            height: auto;
            overflow: visible;
            clip: auto;
            white-space: normal;
            background-color: $white;
            z-index: $zindex-tooltip;
        }
    }
}


// cross and collapse icon
.cross-icon {
    position: relative;

    &::before,
    &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        display: block;
        height: 2px;
        width: 100%;
        background-color: currentColor;
        transition: all 0.2s ease-in-out;
    }

    &::before {
        transform: rotate(-45deg);
    }
    &::after {
        transform: rotate(45deg);
    }
}

.collapse-icon {
    position: absolute;
    width: 0.875rem;
    height: 0.875rem;
    top: 50%;
    right: 0;
    transform: translate(0, -50%);

    .collapsed > & {
        &::before {
            transform: rotate(0);
        }

        &::after {
            transform: rotate(90deg);
        }
    }

    .dropdown-toggle > & {
        &::before {
            transform: rotate(0);
        }

        &::after {
            transform: rotate(90deg);
        }
    }

    .show > .dropdown-toggle > & {
        &::before {
            transform: rotate(-45deg);
        }

        &::after {
            transform: rotate(45deg);
        }
    }
}

@include media-breakpoint-up(lg) {
    .navbar-main {
        .navbar-nav .nav-link {
            display:inline-block;

            &::before {
                display: block;
                content: attr(title);
                font-weight: bold;
                height: 0;
                overflow: hidden;
                visibility: hidden;
            }
        }

        .navbar-nav .nav-link:hover {
            font-weight: 500;
        }

        .navbar-nav .nav-item.dropdown.show {
            .nav-link:hover {
                font-weight: 500;
            }
        }
    }
}

.main-content {
    ul,
    ol {
        li:not(.splide__slide) + li:not(.splide__slide) {
            margin-top: 0.625rem
        }
    }
}

.footer {
    line-height: 1.5;

    a:hover {
        color: $gray-200 !important;
    }

    @include media-breakpoint-down(md){
        /* make space for fixed cta-row */
        padding-bottom: 3.75rem;
    }

    .nav-title {
        font-weight: $font-weight-medium;
        font-size: 1.125rem;
        padding: 0.75rem 0;
        line-height: 1.2222;

        @include media-breakpoint-up(md){
            margin-bottom: 1.125rem;
            padding: 0;
        }

        @include media-breakpoint-up(lg){
            font-size: 1.375rem;
        }
    }

    .sociallink {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid;
        width: 3.25rem;
        height: 3.25rem;
        border-radius: 1.625rem;
        font-size: 1rem;
        margin-right: 0.75rem;

        &:last-child {
            margin-right: 0;
        }

        &:hover {
            border: 2px solid;
        }
    }

    .footer-nav {
        @include media-breakpoint-down(sm){

            &:not(.last) {
                border-bottom: 1px solid $light;
            }

            .nav-title {
                position: relative;
                padding-right: 1rem;
            }
        }

        .list-unstyled {
            margin-bottom: 0.75rem;
            margin-left: 2rem;

            li {
                margin-bottom: 0.6rem;

                a {
                    line-height: 1.1;
                    display: block;
                }
            }

            @include media-breakpoint-up(md){
                margin-bottom: 0;
                margin-left: 0;
            }
        }
    }

    .footer-logos-left {
        a {
            width: 100%;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    .footer-svg-link {
        display: block;
        width: 150px;
        max-width: 100%;
        margin: 0 auto;

        @include media-breakpoint-up(xl) {
            width: 140px;
        }

        svg {
            max-width: 100%;
            height: auto;
        }

        &.footer-svg-left {
            display: flex;
            justify-content: center;

            &.footer-svg-grey {
                filter: grayscale(1);

                &:hover {
                    filter: none;
                }
            }
        }

        @include media-breakpoint-up(md) {
            margin: 0;
        }

        @include media-breakpoint-up(xl) {
            margin: 0 12px;

            &.footer-svg-left {
                justify-content: flex-start;
                margin-left: 0;
            }
        }
    }
}


//************Occupancy Values****************//
.occupancy-item {
    padding: 20px 0;
    width: 70%;
    margin: auto;

    @include media-breakpoint-down(md) {
        width: 45% !important;
    }
}


.occupancy-item .occupancy-values-container {
    position: relative;
    padding-left: 2px;
    padding-right: 2px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.occupancy-item .occupancy-values-row {
    margin-left: -4px;
    margin-right: -4px;
    overflow: hidden;
}

.occupancy-item .occupancy-values-row .occupancy-values-item .base {
    position: absolute;
    left: 2px;
    right: 2px;
    bottom: 0;
    display: block;
    height: 1px;
    background-color: #dee1e4;
}

.occupancy-item .occupancy-values-row .occupancy-values-item {
    float: left;
    position: relative;
    width: 16.667%;
    height: 48px;
    padding-left: 2px;
    padding-right: 2px;
    opacity: 0.2;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.active {
    opacity: 1;
}

.occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle {
    width: 100%;
    height: 0;
    padding-top: 8px;
    overflow: hidden;
    position: absolute;
    top: -8px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
    content: "";
    display: block;
    width: 0;
    height: 0;
    margin-top: -8px;
    border-top: 8px solid transparent;
    border-right: 50px solid #dee1e4;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-2 .base {
    height: 8px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-3 .base {
    height: 16px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-4 .base {
    height: 24px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-5 .base {
    height: 32px;
}

.occupancy-item .occupancy-values-row .occupancy-values-item.item-6>span {
    height: 40px;
}

.occupancy-item .occupancy-values-container .occupancy-tooltip-container {
    position: absolute;
    top: 10px;
    left: 0;
    right: 0;
}

//**occupancy specific colors
.occupancy-schallerbach {
    .occupancy-item .occupancy-values-row .occupancy-values-item .base {
        background-color: $red;
    }

    .occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
        border-right: 50px solid $red;
    }
}

.occupancy-hall {
    .occupancy-item .occupancy-values-row .occupancy-values-item .base {
        background-color: $green;
    }

    .occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
        border-right: 50px solid $green;
    }
}

.occupancy-ischl {
    .occupancy-item .occupancy-values-row .occupancy-values-item .base {
        background-color: $orange;
    }

    .occupancy-item .occupancy-values-row .occupancy-values-item .base .triangle:before {
        border-right: 50px solid $orange;
    }
}

.occupancy-title {
    .btn-link {
        color: $black;

        &:hover {
            h5 {
                font-weight: bold;
            }
        }
    }
}
