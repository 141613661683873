// News

.news {
    .row  > div {
        margin-bottom: 5rem;

        @include media-breakpoint-up(lg) {
            margin-bottom: 6.25rem;
        }
    }

    .list-view-item {
        //margin-bottom: 5rem;
        background: white;
        height: 100%;
        display: flex;
        flex-direction: column;

        .list-view-item-text-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 12px 15px;

            @include media-breakpoint-up (md) {
                padding: 22px 25px;
            }

            .list-view-item-body {
                flex: 1;
            }
        }

        .btn-read-more-teaser {
            .close-text {
                display: none;
            }

            .read-more-text {
                display: flex;
            }

            &:not(.collapsed) {
                .close-text {
                    display: inline;
                }

                .read-more-text {
                    display: none;
                }
            }
        }
    }

    .page-navigation {
        .f3-widget-paginator {
            padding: 0;
            list-style-type: none;
            display: flex;
            justify-content: center;

            li {
                margin-top: 0 !important;
                margin-right: 25px;

                &.current {
                    font-weight: $font-weight-medium;
                    color: $black;
                }

                &:last-child {
                    margin-right: 0;
                }

                a {
                    font-weight: $font-weight-medium;
                    text-decoration: none;

                    &:hover {
                        color: $black;
                    }
                }
            }
        }
    }

    .news-slider-view {
        ul {
            li {
                &:focus {
                    outline: none;
                }

                .list-view-item {
                    margin-bottom: 0.625rem;
                    height: 100%;
                    display: flex;
                    flex-direction: column;

                    .list-view-item-text-wrapper {
                        display: flex;
                        flex-direction: column;
                        height: 100%;

                        .list-view-item-body {
                            flex: 1;
                        }
                    }

                    /*@include media-breakpoint-up(lg) {
                        margin-bottom: 1.875rem;
                    }

                    @include media-breakpoint-up(xl) {
                        margin-bottom: 3.75rem;
                    }*/
                }
            }
        }

        .page-navigation {
            display: none;
        }

        .readmore-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;

            .btn {
                z-index: 102;
            }

           /* @include media-breakpoint-up(lg) {
                display: block;
                margin-top: 0;
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
            }*/
        }
    }

    &:not(.text-teaser) {
        .news-slider-view {
            .splide__arrows {
                margin: 0 !important;
                margin-top: 40px !important;

                @include media-breakpoint-up(lg) {
                    transform: translateY(70px);
                }
            }
        }
    }

    &.news-single {
        .news-img-wrap {
            .mediaelement {
                &-image {
                    display: block;

                    img {
                        width: 100%;
                        margin: 0;
                    }
                }
            }
        }

        .news-maincontent-wrapper {
            margin-top: 5rem;

            @include media-breakpoint-up(lg) {
                margin-top: 6.25rem;
            }
        }

        .news-related-wrap {
            ul {
                list-style-type: none;
                padding-left: 0;

                li {
                    display: flex;

                    a {
                        text-decoration: none;
                    }

                    .news-related-files-size {
                        font-weight: $font-weight-light;
                        margin-left: 10px;
                        color: $black;
                        flex-shrink: 0;
                    }
                }
            }
        }
    }
}
