// Mixins

// Grid system
@mixin make-lg-grid-columns($columns: $grid-columns, $gutter: $grid-gutter-width-lg, $breakpoints: $grid-breakpoints) {
    %grid-column {
        padding-right: $gutter / 2;
        padding-left: $gutter / 2;
    }
}

// Buttons
@mixin button-etr() {
    background-color: $blue;
    border-color: $blue;

    &:hover {
        background-color: darken($blue, 15%);
        border-color: darken($blue, 15%);
    }
}

@mixin button-ets() {
    background-color: $red;
    border-color: $red;

    &:hover {
        background-color: darken($red, 15%);
        border-color: darken($red, 15%);
    }
}

@mixin button-eth() {
    background-color: $green;
    border-color: $green;

    &:hover {
        background-color: darken($green, 15%);
        border-color: darken($green, 15%);
    }
}

@mixin button-eti() {
    background-color: $orange;
    border-color: $orange;

    &:hover {
        background-color: darken($orange, 15%);
        border-color: darken($orange, 15%);
    }
}

@mixin image-object-cover() {
    position: absolute;
    top: 0;
    right: -1px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin custom-form-validation-state($state, $color, $icon) {
    .form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-image: escape-svg($icon);
            background-repeat: no-repeat;
            background-position: right $input-padding-x center;
            background-size: 1rem 1rem;

            @if $state == "invalid" {
                border-color: $red;
                background-color: rgba($red, 0.09);
            }


            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: right $input-padding-x-lg center;
            }
        }
    }

    // stylelint-disable-next-line selector-no-qualifying-type
    textarea.form-control {
        @include form-validation-state-selector($state) {
            padding-right: $input-padding-x * 2;
            background-position: top $input-padding-top right $input-padding-x;

            @include media-breakpoint-up(lg) {
                padding-right: $input-padding-x-lg * 2;
                background-position: top $input-padding-top-lg right $input-padding-x-lg;
            }
        }
    }

    .custom-select {
        @include form-validation-state-selector($state) {
            padding-right: $custom-select-feedback-icon-padding-right;

            @if $state == "invalid" {
                border-color: $red;
                background-color: rgba($red, 0.09);
            }
        }
    }

    .custom-control-input {
        @include form-validation-state-selector($state) {
            //show only invalid state here.. green checkboxes are ugly..
            @if $state == "invalid" {
                ~ .custom-control-label {
                    color: $color;
                }
            }
        }
    }
}