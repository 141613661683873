@import "variables";
//Bootstrap Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
$colors: map-remove($colors, "blue", "indigo", "purple", "pink", "red", "orange", "yellow", "teal", "cyan");
$colors: (
        "gray": $gray-400
);
$theme-colors: map-remove($theme-colors, "info", "warning");

//here because in variables we don't have bootstraps function add
$input-height: add($input-line-height * 1rem, $input-padding-top + $input-padding-bottom);
$input-height-lg: add($input-line-height * 1rem, $input-padding-top-lg + $input-padding-bottom-lg);

//Bootstrap Optional
//@import "bootstrap/scss/forms";--> see custom styles below
//@import "bootstrap/scss/custom-forms"; --> different custom solution below!

//Custom Styles
@import "mixins";
@import "Libraries/Scss/bootstrap-datepicker";


//
// Textual form controls
//

.form-control {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-top $input-padding-x $input-padding-bottom;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: solid $input-border-color $input-border-width;

    .frame-background-light & {
        background-color: $custom-control-indicator-bg;
    }

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius, 0);

    @include transition($input-transition);

    @include media-breakpoint-up(lg) {
        padding: $input-padding-top-lg $input-padding-x-lg $input-padding-bottom-lg;
        height: $input-height-lg;
    }

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
    }

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        outline: 0;
        box-shadow: none;
    }

    // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        font-style: italic;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }

    &:disabled {
        cursor: not-allowed;
    }

    &[type="file"] {
        padding-top: $input-padding-bottom;

        @include media-breakpoint-up(lg) {
            padding-top: $input-padding-bottom-lg;
        }
    }
}

textarea.form-control {
    height: auto;
    min-height: 8rem;
    resize: vertical;
}

// Form groups

.form-group {
    margin-bottom: $form-group-margin-bottom;
}

.form-set {
    margin-bottom: $form-group-margin-bottom;
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$form-grid-gutter-width / 2;
    margin-left: -$form-grid-gutter-width / 2;

    > .col,
    > [class*="col-"] {
        padding-right: $form-grid-gutter-width / 2;
        padding-left: $form-grid-gutter-width / 2;
    }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@each $state, $data in $form-validation-states {
    @include custom-form-validation-state($state, map-get($data, color), map-get($data, icon));
}

// Newsletter validation
.form-control,
.custom-select {
    &.input-validation-error {
        border-color: red;
        background-color: rgba($red, 0.09);
        background-image: escape-svg($form-feedback-icon-invalid);
        background-repeat: no-repeat;
        background-position: right $input-padding-x center;
        background-size: 1rem 1rem;
    }
}

label {
    font-size: $font-size-sm;
    line-height: $input-line-height;
}

// Custom Checkboxes and Radiobuttons

.custom-control {
    position: relative;
    z-index: 1;

    .custom-control-input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        z-index: -1; // Put the input behind the label so it doesn't overlay text
    }

    .custom-control-label {
        cursor: pointer;
        user-select: none;
        position: relative;
        padding-left: $custom-control-gutter + $custom-control-indicator-size;

        &::before {
            position: absolute;
            content: "";
            display: block;
            top: 50%;
            margin-top: -($custom-control-indicator-size / 2);
            left: 0;
            height: $custom-control-indicator-size;
            width: $custom-control-indicator-size;
            background-color: $custom-control-indicator-bg;
            border: solid $input-border-width $input-border-color;

            .frame-background-dark & {
                background-color: $input-bg;
            }
        }

        &::after {
            position: absolute;
            display: none;
            content: '';
            top: 50%;
            margin-top: -11px;
        }
    }

    &:hover .custom-control-label::before {
        background-color: $custom-control-hover-indicator-bg;

        .frame-background-dark & {
            background-color: $input-focus-bg;
        }
    }

    .custom-control-input:focus ~ .custom-control-label::before {
        background-color: $custom-control-hover-indicator-bg;
        .frame-background-dark & {
            background-color: $input-focus-bg;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $custom-control-indicator-checked-bg;
        .frame-background-dark & {
            background-color: $custom-control-indicator-checked-bg;
        }
    }

    .custom-control-input:checked ~ .custom-control-label::after {
        display: block;
    }

    &.custom-control-checkbox {

        .custom-control-label {
            &::before {
                border-radius: $custom-checkbox-indicator-border-radius;
            }

            &::after {
                width: 8px;
                height: 8px;
                left: 9px;
                content: '\00d7';
                font-size: 20px;
            }
        }

    }

    &.custom-control-radio {
        .custom-control-label {
            &::before {
                border-radius: $custom-radio-indicator-border-radius;
            }
            &::after {
                width: 22px;
                height: 22px;
                left: 4px;
                background-color: $red;
                border-radius: $custom-radio-indicator-border-radius;
            }
        }
    }
}

label.form-group-label {
    font-weight: $font-weight-medium;
    font-size: $font-size-base;
    margin-bottom: $label-margin-bottom;

    .required {
        color: $red;
    }
}

.custom-control {
    a {
        text-decoration: underline;
    }

    &.font-xs {
        .custom-control-label {
            font-size: $font-size-sm;
            line-height: $line-height-base;
        }
    }

    &.custom-control-block {
        width: 100%;
        padding-left: 0;

        .custom-control-label {
            display: flex;
            align-items: center;
            width: 100%;
            padding: $custom-control-block-padding $custom-control-block-padding $custom-control-block-padding $custom-control-block-padding + $custom-control-gutter + $custom-control-indicator-size;
            border-radius: $input-border-radius;
            margin-bottom: 0;


        }

        &.custom-control-checkbox {
            .custom-control-label {
                &::after {
                    left: $custom-control-block-padding + 0.5625rem;
                }
            }
        }


    }
}

.newsletter-formular,
.booking-form-container {
    .custom-control-label {
        &::before {
            display: none;
        }
    }

    .control--indicator {
        position: absolute;
        top: -8px;
        left: 0;
        height: 30px;
        width: 30px;
        background: $white;
        border: 1px solid $gray-400;

        &:after {
            content: '\00d7';
            position: absolute;
            display: none;
            align-items: center;
            justify-content: center;
            height: calc(100% - 1px);
            width: calc(100% - 1px);
            color: $black;
            font-size: 20px;
        }
    }

    .control input:checked ~ .control--indicator {
        background: $white;

        &:after {
            display: flex;
        }
    }

    .control:hover input:not([disabled]):checked ~ .control--indicator,
    .control input:checked:focus ~ .control--indicator {
        background: $white;
    }

    .control input:disabled ~ .control--indicator {
        background: $white;
        opacity: 0.6;
        pointer-events: none;
    }
}





// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.
//

.custom-select {
    display: inline-block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-top $input-padding-x $input-padding-bottom;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-placeholder-color;
    font-style: italic;
    vertical-align: middle;
    background: $input-bg $custom-select-background no-repeat right;
    background-position-x: calc(100% - 11px);
    background-size: 30px;
    border: solid $input-border-color $input-border-width;
    @include border-radius($input-border-radius, 0);
    appearance: none;

    @include media-breakpoint-up(lg) {
        padding: $input-padding-top-lg $input-padding-x-lg $input-padding-bottom-lg;
        height: $input-height-lg;
    }

    option {
        &:first-child {
            color: $input-placeholder-color;
            font-style: italic;
        }
    }

    &:focus {
        color: $input-focus-color;
        background-color: $input-focus-bg;
        outline: 0;
        box-shadow: none;

        &::-ms-value {
            // For visual consistency with other platforms/browsers,
            // suppress the default white text on blue background highlight given to
            // the selected option text when the (still closed) <select> receives focus
            // in IE and (under certain conditions) Edge.
            // See https://github.com/twbs/bootstrap/issues/19398.
            color: $input-color;
            background-color: $input-bg;
        }
    }

    // Hides the default caret in IE11
    &::-ms-expand {
        display: none;
    }

    // Remove outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $custom-select-color;
    }
}

.form-group-select {
    position: relative;
    label {
        position: absolute;
        left: $input-padding-x;
        top: 0.25rem;
        color: $input-color;
        font-size: 0.625rem;

        @include media-breakpoint-up(lg) {
            left: $input-padding-x-lg;
            top: 0.5rem;
        }
    }
}

.help-block {
    font-size: 80%;
    line-height: $line-height-base;
    display: inline-block;
    color: red;
}

.form-ce {
    p {
        line-height: 1.3;
    }
}

input.datepicker {
    background-image: $calendar-image;
    background-size: 1rem;
    background-position: right 1rem center;
    background-repeat: no-repeat;
}

.datepicker {
    &.dropdown-menu {
        background-color: $white;
        color: $black;
        border: solid 1px $black;
        border-radius: 0;

        th,
        td {
            padding: 3px;

            &.active {
                background-color: $primary !important;
                background-image: none;
            }
        }
    }
}

.form-control[readonly] {
    background-color: #fff;
}
