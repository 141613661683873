//
// Basic Bootstrap table
//

.table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.

    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: $table-border-width solid $table-border-color;
    }

    thead th {
        font-weight: $font-weight-base;
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }
}


//
// Condensed table w/ half padding
//

.table-sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}


// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {

  th,
  td {
        border: $table-border-width solid $table-border-color;

      &:first-child {
          border-left-width: 0;
      }

      &:last-child {
          border-right-width: 0;
      }
  }

  thead {
    th,
    td {
        border-bottom-width: 2 * $table-border-width;
        border-top-width: 0;
    }
  }
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
  @each $breakpoint in map-keys($grid-breakpoints) {
    $next: breakpoint-next($breakpoint, $grid-breakpoints);
    $infix: breakpoint-infix($next, $grid-breakpoints);

    &#{$infix} {
      @include media-breakpoint-down($breakpoint) {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;

        // Prevent double border on horizontal scroll due to use of `display: block;`
        > .table-bordered {
          border: 0;
        }
      }
    }
  }
}
