.btn.infopopup-btn {
  position: fixed;
  left: 10px;
  bottom: 70px;
  display: block;
  transform: rotate(-10deg);
  width: 65px;
  height: 65px;
  padding: 10px;
  border: 0;
  border-radius: 50%;
  box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
  z-index: 900;
  appearance: none;
  pointer-events: auto;


  &:focus,
  &.focus {
    outline-color: $gray-500;
  }

  .svg-icon {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-up(lg) {
    width: 80px;
    height: 80px;
    padding: 14px;
    bottom: 10px;
  }
}

.infopopup-items-wrapper {
  position: fixed;
  display: flex;
  align-items: flex-end;
  top: 15px;
  right: 15px;
  left: 15px;
  bottom: 75px;
  z-index: 900;
  pointer-events: none;

  &.show-it {
    .infopopup-inner {
      animation-name: infoIn;
      animation-duration: 250ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }

  &.hide-it {
    .infopopup-inner {
      animation-name: infoOut;
      animation-duration: 250ms;
      animation-timing-function: linear;
      animation-fill-mode: forwards;
    }
  }


  .infopopup-inner {
    position: relative;
    padding: 3.25rem 1.25rem 1.25rem;
    width: 100%;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    box-shadow: 2px 2px 4px 2px rgba($black, 0.15);
    background-color: $white;
    pointer-events: auto;
  }

  @include media-breakpoint-up(sm) {
    right: auto;
    width: 430px;
  }

  @include media-breakpoint-up(lg) {
    left: 25px;
    bottom: 25px;

    .infopopup-inner {
      padding: 3.25rem 2.25rem 2.25rem;
    }
  }
}

.infopopup-close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 26px;
  height: 26px;
  display: block;
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;


  &:focus,
  &.focus {
    outline-color: $gray-500;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    left: 12px;
    top: 0;
    height: 26px;
    width: 1px;
    background-color: $dark;
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

@keyframes infoIn{
  0%{
    opacity: 0;
    transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);
  }
}

@keyframes infoOut{
  0%{
    opacity: 1;
    transform: scale(1) translate3d(0, 0, 0);

  }
  100%{
    opacity: 0;
    transform: scale(0.8) translate3d(-3.5rem, 2.5rem, 0);
  }
}
