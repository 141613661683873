// Teaser



.teaser {
    a {
        text-decoration: none;
    }

    .teaser-row & {
        margin-bottom: $grid-gutter-width;

        @include media-breakpoint-down(sm) {
            max-width: 336px;
            margin-left: auto;
            margin-right: auto;
        }

        @include media-breakpoint-down(xs) {
            max-width: 294px;
        }

        @include media-breakpoint-up(lg) {
            margin-bottom: $grid-gutter-width-lg;
        }
    }
}

.teaser-top {
    position: relative;
    height: 0;
    overflow: hidden;
    color: $white;
    padding-bottom: 150%;
    //margin-top: 100px;

    &:hover {
        picture img {
            filter: brightness(1);
        }
    }

    img {
        filter: brightness(0.8);
    }

    picture {
        &::after {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(to top,rgba(0,0,0,.55) 0,rgba(0,0,0,0) 40%);
        }
    }


    .teaser-nav & {
        padding-bottom: 75%;

        .teaser-title {
            font-size: 1.375rem;

            .teaser-arrow {
                top: -3px;
            }
        }
    }

    .img-fluid {
        min-width: 100%;
    }

    .teaser-title {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        font-size: 1.5rem;
        font-weight: 400;
        line-height: 1.1;
        padding: 1.75rem 1rem;

        @include media-breakpoint-up(xl) {
            font-size: 2.125rem;
            padding: 1.75rem 1.5rem;
        }

        .teaser-arrow {
            position: relative;
            white-space: nowrap;
            top: -7px;
            font-size: 1.25rem;
        }
    }
}

.teaser-bottom {
    margin-top: 0.875rem;
    padding: 0 1rem;
    color: $body-color;
    margin-bottom: 20px;

    @include media-breakpoint-up(xl) {
        padding: 0 1.5rem;
    }

    .teaser-nav & {
        color: $white;
    }
}

@media (min-width: 992px) and (max-width: 1199.99px) {
    .teaser-nav {
        .teaser-title {
            font-size: 1.125rem;

            .teaser-arrow {
                font-size: 0.9375rem;
            }
        }

        .teaser-subtitle {
            font-size: 1rem;
        }
    }
}
